import { FC } from "react";
import { useTranslation } from "next-i18next";
import ContainerDividerSpanFirst from "@components/dividers/container-divider-span-first";
import { ServiceCardIconImageTopWithLink } from "@components/cards/service-card-icon-image-top-with-link";
import { ServicesHomeData } from "@data/home-services-data";
import {
  i18translateType,
  PagePropsBasicType,
} from "../../../../@types/types-body";

export const ServicesHome: FC<PagePropsBasicType> = ({ namespace }) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <section id="services-home" className="my-24">
      <div className="container mx-auto">
        <ContainerDividerSpanFirst
          id={"home-1"}
          title={"services.title"}
          text={"services.text"}
          span={"services.span"}
          namespace={namespace}
          subtitle={"services.subtitle"}
        />
        <div className="grid gap-x-[50px] grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-[-30px] xl:px-[35px] mb-16">
          {ServicesHomeData.map((service) => (
            <ServiceCardIconImageTopWithLink
              key={`service-card-for-${service.id}`}
              id={service.id}
              namespace={namespace}
              icon={service.logo}
              text={`services.cards.${service.name}.shortDescription`}
              title={`services.cards.${service.name}.title`}
              path={`common:menu.path.${service.name}`}
              serviceName={service.name}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
