import { FC } from "react";
import { useTranslation } from "next-i18next";
import {
  i18translateType,
  ServiceCardIconImageTopType,
} from "../../@types/types-body";
import Image from "next/image";
import Link from "next/link";

export const ServiceCardIconImageTopWithLink: FC<
  ServiceCardIconImageTopType
> = ({ id, namespace, text, title, icon, path, serviceName }) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <div
      className="group mb-[30px] border relative transition-all drop-shadow-[0px_10px_20px_rgba(0,0,0,0.15)] rounded-[10px]
            transition ease-in-out delay-150  hover:shadow-2xl duration-300 flex shrink flex-col "
      key={`service-card-for-${id}`}
    >
      <div className="relative flex flex-wrap justify-between relative rounded-t-xl min-h-[250px]">
        <div
          className="group-hover:hidden z-50 h-full w-full absolute rounded-t-xl"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5))`,
          }}
        />
        <Image
          src={icon}
          className="rounded-t-xl object-cover"
          fill={true}
          alt={t(`${title}`)}
        />
      </div>
      <div className="px-[10px] xl:px-[15px] self-end flex flex-col h-full">
        <h3 className="my-[5px] text-lg text-center font-bold text-crowlyn-night">
          {t(`${title}`)}
        </h3>
        <p className="font-light text-base text-justify">{t(`${text}`)}</p>
        <Link href={t(`${path}`)} className="self-end">
          <div
            className={
              "flex flex-row mt-4 w-full align-end justify-end whitespace-nowrap flex-wrap"
            }
          >
            {t("i_would_like_more_info")}{" "}
            <span className="text-crowlyn ml-2">
              {t(`services.services.${serviceName}`)}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};
