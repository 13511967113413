import type {
  GetServerSideProps,
  InferGetStaticPropsType,
  NextPage,
} from "next";
import CrowlynHead from "@components/crowlyn-head/crowlyn-head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { Header } from "@components/sections/header/header";
import { BannerObjectType } from "../@types/types-header";
import { ServicesHome } from "@components/sections/services/services-home/services-home";
import { Footer } from "@components/sections/footer/footer";
import { useRouter } from "next/router";
import { i18translateType } from "../@types/types-body";
import dynamic from "next/dynamic";

type HomePageProps = InferGetStaticPropsType<typeof getServerSideProps>;

const WhyChooseUs = dynamic(
  () => import("@components/sections/why-choose-us/why-choose-us"),
  {
    suspense: true,
  }
);


const Testimonials = dynamic(
  () => import("@components/sections/testimonials/testimonials"),
  {
    suspense: true,
  }
);
const BenefitsHome = dynamic(
  () => import("@components/sections/benefits/benefits-cooperation"),
  {
    suspense: true,
  }
);

const HomePage: NextPage<HomePageProps> = (server_side_props: any) => {
  const namespace: string = "home";
  const { t }: i18translateType = useTranslation(namespace);
  const router = useRouter();
  const bannerObject: BannerObjectType = {
    backgroundImage: "/images/banners/home.webp",
    buttonLink: t("common:menu.path.contact"),
  };
  return (
    <>
      <CrowlynHead
        description={t("seo_description")}
        title={t("seo_title")}
        keywords={t("seo_keywords")}
        lang={server_side_props.locale ?? router.locale}
        path={router.pathname}
        englishPath={""}
        germanPath={""}
        image={"/images/banners/home-banner.webp"}
      />
      <Header banner={bannerObject} namespace={namespace} />
      <ServicesHome namespace={namespace} />
      <WhyChooseUs namespace={namespace} />
      <Testimonials namespace={namespace} />
      <BenefitsHome namespace={namespace} />
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale as string, ["common", "home"])),
    },
  };
};

export default HomePage;
