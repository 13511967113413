import { FC } from "react";
import {
  ContainerDividerType,
  i18translateType,
} from "../../@types/types-body";
import { useTranslation } from "next-i18next";

const ContainerDividerSpanFirst: FC<ContainerDividerType> = ({
  id,
  title,
  text,
  span,
  subtitle,
  namespace,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <div
      key={id}
      className="section-title relative z-20 text-center mb-7.5 lg:mb-15"
    >
      <div className="font-medium text-sm md:text-base leading-none -tracking-tightest block mb-2.5 uppercase">
        <span className="border-b-2 px-8">{t(`${title}`)}</span>
      </div>
      <h2 className="my-2 title m-0 child:text-crowlyn text-2xl md:text-4xl lg:text-5xl leading-heading lg:leading-heading">
        <span className="font-bold">{t(`${span}`)} </span> {t(`${subtitle}`)}
      </h2>
      <p className="text-justify md:text-center">{t(`${text}`)}</p>
    </div>
  );
};

export default ContainerDividerSpanFirst;
