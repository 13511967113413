import WebAppWebp from "@images/home/web-service.webp";
import MobileAppWebp from "@images/home/mobile-app-service.webp";
import Consultation from "@images/home/consultation.webp";

export const ServicesHomeData: Array<{ id: number; name: string; logo: any }> =
  [
    {
      id: 1,
      name: "web-development-service",
      logo: WebAppWebp,
    },
    {
      id: 2,
      name: "mobile-development",
      logo: MobileAppWebp,
    },
    {
      id: 3,
      name: "consultation",
      logo: Consultation,
    },
  ];
